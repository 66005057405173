<template>
    <a-card>
        <a-page-header
            :title='id == 0 ? "新建任务" : "修改任务"'
        />
        <div class="steps-box">
            <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
            </a-steps>
        </div>
        <div class="steps-content">
            <div class="content" v-show="current == 0">
                <a-form-model
                    ref="dataFormFirst"
                    :model="dataFormFirst"
                    :rules='rulesFirst'
                    v-bind='layout'
                >
                    <a-form-model-item label="项目名称">
                        <a-select
                            v-model="dataFormFirst.projectId"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                            @search="onSearchProject"
                            @change="onChangeProject"
                        >
                            <a-select-option
                                v-for="item in projectList"
                                :key="item.id"
                            >
                                {{ item.projectName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="项目编码">
                        {{ dataFormFirst.projectCode || '-' }}
                    </a-form-model-item>
                    <a-form-model-item label="项目品牌">
                        {{ dataFormFirst.projectPrincipalName || '-' }}
                    </a-form-model-item>
                    <a-form-model-item label="任务KPI类型" prop='kpiType'>
                        <a-radio-group v-model="dataFormFirst.kpiType">
                            <a-radio :value="1">线索量</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="广告平台" prop='adPlatformType'>
                        <a-radio-group v-model="dataFormFirst.adPlatformType">
                            <a-radio :value="item.id" v-for="item in platformList" :key="item.id">{{ item.adPlatformName }}</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="投放结构" prop='deliveryStruct'>
                        <a-radio-group v-model="dataFormFirst.deliveryStruct">
                            <a-radio :value="1">单车系投放A</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="content" v-show="current == 1">
                <a-form-model
                    ref="dataFormSecond"
                    :model="dataFormSecond"
                    :rules='rulesSecond'
                    v-bind='layout2'
                >
                    <a-form-model-item label="任务周期" prop="pickerTime">
                        <a-row>
                            <a-col :span="17">
                                <a-range-picker v-model="dataFormSecond.pickerTime" :placeholder="['开始时间', '结束时间']" @change="onChangeTime" class="w-100">
                                    <a-icon slot="suffixIcon" type="calendar" />
                                </a-range-picker>
                            </a-col>
                            <a-col :span="1"></a-col>
                            <a-col :span="6">共<span class="red">{{ totalDay || 0 }}</span>天</a-col>
                        </a-row>
                    </a-form-model-item>
                    <a-form-model-item label="任务总预算" prop="totalBudget">
                        <a-row>
                            <a-col :span="17">
                                <a-input-number v-model="dataFormSecond.totalBudget" :min="0" :precision="0" class="w-100" />
                            </a-col>
                            <a-col :span="1"></a-col>
                            <a-col :span="6">{{ formatUnit(dataFormSecond.totalBudget) }}</a-col>
                        </a-row>
                    </a-form-model-item>
                    <a-form-model-item label="任务日预算" prop="dailyBudget">
                        <a-row>
                            <a-col :span="17">
                                <a-input-number v-model="dataFormSecond.dailyBudget" :min="0" :precision="0" class="w-100" />
                            </a-col>
                            <a-col :span="1"></a-col>
                            <a-col :span="6">{{ formatUnit(dataFormSecond.dailyBudget) }}</a-col>
                        </a-row>
                    </a-form-model-item>
                    <a-form-model-item label="任务总KPI" prop="totalKpi">
                        {{ dataFormSecond.totalKpi || '-' }}
                    </a-form-model-item>
                    <a-form-model-item label="品牌车系" ref="carSeriesId" prop="carSeriesId">
                        <a-row>
                            <a-col :span="8">
                                <a-select
                                    v-model="dataFormSecond.principalId"
                                    placeholder="请选择品牌"
                                    allow-clear
                                    show-search
                                    option-filter-prop="children"
                                    @change="onChangePrincipal"
                                >
                                    <a-select-option
                                        v-for="item in principalList"
                                        :key="item.id"
                                        :name="item.principal_name"
                                    >
                                        {{ item.principal_name }}
                                    </a-select-option>
                                </a-select>
                            </a-col>
                            <a-col :span="1"></a-col>
                            <a-col :span="8">
                                <a-select
                                    v-model="dataFormSecond.carSeriesId"
                                    placeholder="请选择车系"
                                    allow-clear
                                    show-search
                                    option-filter-prop="children"
                                    @change="onChangeCarSeries"
                                >
                                    <a-select-option
                                        v-for="item in seriesList"
                                        :key="item.id"
                                        :name="item.name"
                                    >
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-col>
                            <a-col :span="1"></a-col>
                            <a-col :span="4">
                                <base-button
                                    :title="'添加'"
                                    @onClickBtn="handleAddCar"
                                ></base-button>
                            </a-col>
                        </a-row>
                    </a-form-model-item>
                    <a-form-model-item label=" " :colon="false" :wrapperCol="{span: 19}">
                        <div class="table">
                            <div class="row">
                                <div class="col col-1">品牌</div>
                                <div class="col col-1">车系</div>
                                <div class="col col-1">任务明细KPI</div>
                                <div class="col col-1">计划目标出价（元）</div>
                                <div class="col col-1">计划日预算（元）</div>
                                <div class="col col-1">操作</div>
                            </div>
                            <div class="row">
                                <div class="col col-2">批量录入</div>
                                <div class="col col-1">
                                    <a-input-number v-model="batchData.detailKpi" :min="0" :precision="0" class="w-70p" />
                                    <span class="link" @click="handleBatch('detailKpi', '任务明细KPI')">录入</span>
                                </div>
                                <div class="col col-1">
                                    <a-input-number v-model="batchData.targetBid" :min="0" :precision="2" class="w-70p" />
                                    <span class="link" @click="handleBatch('targetBid', '计划目标出价')">录入</span>
                                </div>
                                <div class="col col-1">
                                    <a-input-number v-model="batchData.dailyBudget" :min="300" :precision="0" class="w-70p" />
                                    <span class="link" @click="handleBatch('dailyBudget', '计划日预算')">录入</span>
                                </div>
                                <div class="col col-1"></div>
                            </div>
                            <div class="row" v-for="(item, index) in dataFormSecond.carSeriesRelationList" :key="index">
                                <a-form-model-item :colon="false" 
                                    :prop="'carSeriesRelationList.' + index"
                                    :rules="rulesSecond.carSeriesRelationList"
                                    :wrapperCol="{span: 24}"
                                >
                                    <div class="row row-1">
                                        <div class="col col-1">{{ item.principalName }}</div>
                                        <div class="col col-1">{{ item.carSeriesName }}</div>
                                        <div class="col col-1">
                                            <a-input-number v-model="item.detailKpi" :min="0" :precision="0" class="w-70p" @change="onChangeInput" />
                                        </div>
                                        <div class="col col-1">
                                            <a-input-number v-model="item.targetBid" :min="0" :precision="2" class="w-70p" />
                                        </div>
                                        <div class="col col-1">
                                            <a-input-number v-model="item.dailyBudget" :min="300" :precision="0" class="w-70p" />
                                        </div>
                                        <div class="col col-1">
                                            <span class="link" @click="handleDel(index)">删除</span>
                                        </div>
                                    </div>
                                </a-form-model-item>
                            </div>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="投放位置" prop="placementArr">
                        <a-checkbox-group v-model="dataFormSecond.placementArr" :options="placementOptions" @change=onChangePlacement />
                    </a-form-model-item>
                    <a-form-model-item label="集客场景" prop="customerSceneArr">
                        <a-checkbox-group v-model="dataFormSecond.customerSceneArr" :options="customerSceneList" />
                    </a-form-model-item>
                    <a-form-model-item label="出价类型" prop="bidTypeArr">
                        <a-checkbox-group v-model="dataFormSecond.bidTypeArr" :options="bidTypeOptions" />
                    </a-form-model-item>

                    <a-form-model-item label="自定义定向人群" ref="includeCrowd" prop="includeCrowd" :wrapperCol="{span: 19}">
                        <a-radio-group v-model="dataFormSecond.includeCrowd" @change="e => onChangeCrowd(e, 'include_crowd_pack_id_arr')">
                            <a-radio :value="1">不限</a-radio>
                            <a-radio :value="2">
                                选择选择数据源（多选）
                                <a-select
                                    mode="multiple"
                                    v-model="(dataFormSecond.dyUserTarget || {}).include_crowd_pack_id_arr"
                                    placeholder="请选择"
                                    show-search
                                    option-filter-prop="children"
                                    :disabled="dataFormSecond.includeCrowd != 2"
                                    @change="onSelectCrowd('includeCrowd')"
                                >
                                    <a-select-option
                                        v-for="item in crowdList"
                                        :key="item.id"
                                        :disabled="(dataFormSecond.dyUserTarget || {}).exclude_crowd_pack_id_arr.indexOf(item.id) != '-1'"
                                    >
                                        {{ item.sourceName }}
                                    </a-select-option>
                                </a-select>
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item ref="excludeCrowd" prop="excludeCrowd" :wrapperCol="{span: 19}">
                        <span slot="label">
                            自定义<span class="red">排除</span>人群
                        </span>
                        <a-radio-group v-model="dataFormSecond.excludeCrowd" @change="e => onChangeCrowd(e, 'exclude_crowd_pack_id_arr')">
                            <a-radio :value="1">不限</a-radio>
                            <a-radio :value="2">
                                选择选择数据源（多选）
                                <a-select
                                    mode="multiple"
                                    v-model="(dataFormSecond.dyUserTarget || {}).exclude_crowd_pack_id_arr"
                                    placeholder="请选择"
                                    show-search
                                    option-filter-prop="children"
                                    :disabled="dataFormSecond.excludeCrowd != 2"
                                    @change="onSelectCrowd('excludeCrowd')"
                                >
                                    <a-select-option
                                        v-for="item in crowdList"
                                        :key="item.id"
                                        :disabled="(dataFormSecond.dyUserTarget || {}).include_crowd_pack_id_arr.indexOf(item.id) != '-1'"
                                    >
                                        {{ item.sourceName }}
                                    </a-select-option>
                                </a-select>
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>


                    <a-form-model-item label=" " :colon="false" ref="districtValue" prop="districtValue" :wrapperCol="{span: 19}">
                        <DistrictSelection
                            :cur-value="districtCurValue"
                            :extensions="districtExtensions"
                            @receive="districtReceive"
                        />
                    </a-form-model-item>

                    <a-form-model-item label="任务名称" prop="missionName">
                        <a-input v-model="dataFormSecond.missionName" :maxLength="30" @change="onEditMissionName" />
                    </a-form-model-item>

                    <a-form-model-item label=" " :colon="false" ref="audienceValue" prop="audienceValue" :wrapperCol="{span: 19}">
                        <a-collapse v-model="collapseActiveKey" @change="changeActivekey">
                            <a-collapse-panel key="1" :header="collapseActiveKey == 1 ? '收起' : '展开'">
                                <AudienceTarget
                                    :cur-value="audienceCurValue"
                                    :extensions="audienceExtensions"
                                    @receive="audienceReceive"
                                />
                            </a-collapse-panel>
                        </a-collapse>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
        <div class="steps-action">
            <a-space>
                <base-button
                    :title="'取消'"
                    @onClickBtn="handleBack"
                ></base-button>

                <base-button
                    :type="'primary'"
                    :title="'下一步'"
                    @onClickBtn="next"
                    v-if="current < steps.length - 1"
                ></base-button>

                <base-button
                    :type="'primary'"
                    :title="'提交'"
                    :loading="isSubmit"
                    :disabled="isSubmit"
                    @onClickBtn="handleSubmit"
                    v-if="current == steps.length - 1"
                ></base-button>
                
                <base-button
                    :title="'上一步'"
                    @onClickBtn="prev"
                    v-if="current > 0"
                ></base-button>
            </a-space>
        </div>
    </a-card>
</template>

<script>
import { DistrictSelection, AudienceTarget } from '@bytedance-ad/mui-vue2';
import moment from 'moment';
import _ from 'lodash'

export default {
    components: {
        DistrictSelection,
        AudienceTarget,
    },
    data() {
        this.onChangeInput = _.debounce(this.onChangeInput, 1000)

        let checkCarDataLength = (rule, value, callback) => {
            if(this.dataFormSecond.carSeriesRelationList.length < 1) {
                callback(new Error(`请选择品牌 - 车系`))
            } else {
                callback();
            }
        }

        let checkCarData = (rule, value, callback) => {
            if(!value.detailKpi || !value.targetBid || !value.dailyBudget) {
                callback(new Error(`请填写任务总KPI / 任务总预算 / 任务日预算`))
            } else {
                callback();
            }
        }

        let checkIncludeCrowd = (rule, value, callback) => {
            if(value == 1 || value == 2 && this.dataFormSecond.dyUserTarget.include_crowd_pack_id_arr.length > 0) {
                callback();
            } else {
                callback(new Error(`请选择自定义定向人群`))
            }
        }

        let checkExcludeCrowd = (rule, value, callback) => {
            if(value == 1 || value == 2 && this.dataFormSecond.dyUserTarget.exclude_crowd_pack_id_arr.length > 0) {
                callback();
            } else {
                callback(new Error(`请选择自定义定向人群`))
            }
        }

        return {
            id: this.$route.query.id,
            pageType: this.$route.query.type,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            layout2: {
                labelCol: { span: 4 },
                wrapperCol: { span: 13 }
            },
            formItemLayoutGrass: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 5 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 18 },
                },
            },
            dataFormFirst: {
                projectId: undefined,
                kpiType: 1,
                adPlatformType: '1',
                deliveryStruct: 1,
            },
            rulesFirst: {
                kpiType: [
                    { required: true, message: '请选择任务KPI类型', trigger: 'change' }
                ],
                adPlatformType: [
                    { required: true, message: '请选择广告平台', trigger: 'change' }
                ],
                deliveryStruct: [
                    { required: true, message: '请选择投放结构', trigger: 'change' }
                ],
            },
            dataFormSecond: {
                pickerTime: [moment(), moment()],
                startTime: `${moment().format('YYYY-MM-DD')} 00:00:00`,
                endTime: `${moment().format('YYYY-MM-DD')} 23:59:59`,
                totalBudget: '',
                dailyBudget: '',
                totalKpi: '',
                carSeriesRelationList: [],
                principalId: undefined,
                principalName: '',
                carSeriesId: undefined,
                carSeriesName: '',
                placementArr: [1],
                customerSceneArr: [1],
                bidTypeArr: [1],
                missionName: '',
                districtValue: '',
                audienceValue: 'OK',
                dyUserTarget: {
                    include_crowd_pack_id_arr: [],
                    exclude_crowd_pack_id_arr: []
                },
                includeCrowd: 1,
                excludeCrowd: 1,
            },
            rulesSecond: {
                pickerTime: [
                    { type: 'array', required: true, message: '请选择任务周期', trigger: 'change' }
                ],
                totalBudget: [
                    { required: true, message: '请填写任务总预算', trigger: 'change' }
                ],
                dailyBudget: [
                    { required: true, message: '请填写任务日预算', trigger: 'change' }
                ],
                totalKpi: [
                    { required: true, message: '请填写任务总KPI', trigger: 'change' }
                ],
                carSeriesId: [
                    { required: true, validator: checkCarDataLength, trigger: 'change' }
                ],
                carSeriesRelationList: [
                    { validator: checkCarData, trigger: 'change' }
                ],
                placementArr: [
                    { type: 'array', required: true, message: '请选择投放位置', trigger: 'change' }
                ],
                customerSceneArr: [
                    { type: 'array', required: true, message: '请选择集客场景', trigger: 'change' }
                ],
                bidTypeArr: [
                    { type: 'array', required: true, message: '请选择出价类型', trigger: 'change' }
                ],
                missionName: [
                    { required: true, message: '请填写任务名称', trigger: 'change' }
                ],
                districtValue: [
                    { required: true, message: '请填写地域', trigger: 'change' }
                ],
                audienceValue: [
                    { required: true, message: '请填写行为兴趣、抖音达人等相关信息', trigger: 'change' }
                ],
                includeCrowd: [
                    { required: true, validator: checkIncludeCrowd, trigger: 'change' }
                ],
                excludeCrowd: [
                    { required: true, validator: checkExcludeCrowd, trigger: 'change' }
                ],
            },
            current: 0,
            steps: [
                { title: '选项目和广告平台', },
                { title: '选择投放内容', },
            ],
            isSubmit: false,
            projectList: [],
            districtCurValue: { 
                district: "REGION",
            },
            districtExtensions: {
                isV1: true,
            },
            audienceCurValue: {
                age: ["AGE_BETWEEN_18_23", "AGE_BETWEEN_24_30", "AGE_BETWEEN_31_40", "AGE_BETWEEN_41_49", "AGE_ABOVE_50"],
                interest_action_mode: "RECOMMEND",
                landing_type: '',
            },
            audienceExtensions: {
                hiddenComponents: [ //隐藏项
                    'district', // 地域
                    'crowd', //人群
                    'superior',
                    'platform', //平台
                    'device', //设备
                    'network', //网络
                    'hide_is_exists', // 已安装用户
                    'transform', //过滤已转化用户
                    // 'transform_time',
                    'mobile_brand', //手机品牌
                    'mobile_price', //手机价格
                    'smart', //智能放量
                ],
                landing_type: 'age'
            },
            dateFormat: 'YYYY-MM-DD',
            totalDay: 0,
            principalList: [],
            seriesList: [],
            batchData: {
                detailKpi: '',
                targetBid: '',
                dailyBudget: '',
            },
            platformList: [],
            placementOptions: [
                { label: '抖音', value: 1, disabled: true },
                { label: '今日头条', value: 2 },
                { label: '西瓜视频', value: 3 },
            ],
            customerSceneList: [],
            bidTypeOptions: [
                { label: '常规投放', value: 1 },
                { label: '放量投放', value: 2 },
                { label: '最大转化投放', value: 3 },
            ],
            collapseActiveKey: '1',
            isEditMissionName: true,
            crowdList: [],
        }
    },
    created() {
        this.id != 0 ? this.getDetail() : ''

        this.getProjectList()
        this.getAdPlatformList()
        this.getCustomerScene()
        this.getPrincipalList()
        this.getAudienceDataSourceList()
    },
    methods: {
        //获取项目列表
        getProjectList(val) {
            let params = {
                projectName: val,
                page: 1,
                size: 1000
            }
            this.$api.core.deliverManage.getProjectList(params).then((res) => {
                if(res.code == 200) {
                    this.projectList = res.data.list || []
                } else {
                    this.projectList = []
                    this.$message.error(`获取项目列表失败,${res.message}`)
                }
            })
        },
        //获取项目详情
        getProjectDetail(id) {
            this.$api.core.deliverManage.getProjectDetail(id).then((res) => {
                if(res.code == 200) {
                    let _data = res.data

                    this.$set(this.dataFormFirst, 'projectCode', _data.projectCode)
                    this.$set(this.dataFormFirst, 'projectPrincipalName', _data.principalName)
                } else {
                    this.$message.error(`获取项目详情,${res.message}`)
                }
            })
        },
        onSearchProject(val) {
            this.getProjectList(val)
        },
        onChangeProject() {
            this.getProjectDetail(this.dataFormFirst.projectId)
        },
        // 获取广告平台
        getAdPlatformList() {
            this.$api.core.pphtManage.getAdPlatformList().then(res => {
                if(res.code == 200) {
                    this.platformList = res.data
                } else {
                    console.error(`获取平台列表失败，${res.message}`)
                }
            })
        },
        // 获取集客场景
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                    this.customerSceneList.map(item => {
                        item.label = item.desc
                        item.value = item.code
                    })
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        // 获取人群
        getAudienceDataSourceList() {
            let params = {
                page: 1,
                size: 1000
            }
            this.$api.core.materialManage.getAudienceDataSourceList(params).then(res => {
                if(res.code == 200) {
                    this.crowdList = res.data
                } else {
                    console.error(`获取人群列表失败，${res.message}`)
                }
            })
        },
        onChangeCrowd(e, type) {
            let val = e.target.value

            if(val == 1) {
                this.dataFormSecond.dyUserTarget[type] = []
            } 
        },
        onSelectCrowd(type) {
            this.$refs[type].onFieldChange()
        },
        // 下一步
        next() {
            this.$refs.dataFormFirst.validate((valid, obj) => {
                if (valid) {
                    this.current++;
                }
            })
        },
        // 上一步
        prev() {
            this.current--;
        },
        // 计算天数
        onChangeTime(date, dateString) {
            let startTime = dateString[0]
            let endTime = dateString[1]
            this.pickerTime = date
            this.totalDay = moment(endTime).diff(startTime, 'day') + 1
            this.dataFormSecond.startTime = `${startTime} 00:00:00`
            this.dataFormSecond.endTime = `${endTime} 23:59:59`
            if(this.isEditMissionName) {
                this.dataFormSecond.missionName = this.formatMissionName()
            }
        },
        // 单位转化为万，保留4位数
        formatUnit(value = 0) {
            let val = Number(value)
            return (val/1e4).toFixed(4) + '万'
        },
        changeActivekey(val) {
            this.collapseActiveKey = val
        },
        // 地域定向
        districtReceive(value) {
            console.log('districtReceive', {value})
            let districtState = false
            
            if(value.district == 'REGION') {
                if(value.city.length > 0) {
                    districtState = true
                } else {
                    districtState = false
                }
            } else {
                districtState = true
            }

            if(districtState) {
                this.dataFormSecond.districtValue = 'OK'
            } else {
                this.dataFormSecond.districtValue = ''
            }

            console.log(value)
            
            this.districtCurValue = value
            this.$refs.districtValue.onFieldChange()
        },
        // 人群定向
        audienceReceive(value) {
            console.log('audienceReceive', {value})
            let interestState = true
            let awemeState = true
            
            if(value.interest_action_mode == 'CUSTOM') {
                if(value.action_categories.length > 0 && value.interest_categories.length > 0) {
                    interestState = true
                } else {
                    interestState = false
                }
            } else {
                interestState = true
            }

            if(!!value.aweme_fan_time_scope) {
                if(!!value.aweme_fan_categories || !!value.aweme_fan_accounts) {
                    awemeState = true
                } else {
                    awemeState = false
                }
            } else {
                awemeState = true
            }

            if(interestState && awemeState) {
                this.dataFormSecond.audienceValue = 'OK'
            } else {
                this.dataFormSecond.audienceValue = ''
            }

            this.audienceCurValue = value
            this.$refs.audienceValue.onFieldChange()
        },
        //获取详情
        getDetail() {
            this.$api.core.deliverManage.getTaskDetail(this.id).then((res) => {
                if(res.code == 200) {
                    this.isEditMissionName = false

                    let _data = res.data

                    let dataFormFirst = {
                        projectId: this.pageType == 'copy' ? undefined : _data.projectId,
                        projectCode: this.pageType == 'copy' ? undefined : _data.projectCode,
                        projectPrincipalName: this.pageType == 'copy' ? undefined : _data.projectPrincipalName,
                        kpiType: _data.kpiType,
                        adPlatformType: _data.adPlatformType ? (_data.adPlatformType).toString() : undefined,
                        deliveryStruct: _data.deliveryStruct,
                    }
                    this.$set(this, 'dataFormFirst', dataFormFirst)

                    //默认选抖音，且抖音不可修改
                    let placementArr = _data.placementArr
                    if(placementArr) {
                        _data.placementArr = placementArr.some(item => item == 1) ? placementArr : [...placementArr, ...[1]]
                    } else {
                        _data.placementArr = [1]
                    }

                    this.dataFormSecond = {
                        ...this.dataFormSecond,
                        ..._data
                    }
                    
                    this.dataFormSecond.pickerTime = [
                        moment(_data.startTime),
                        moment(_data.endTime)
                    ]
                    this.totalDay = moment(_data.endTime).diff(_data.startTime, 'day') + 1

                    if((_data.dyUserTarget.include_crowd_pack_id_arr || []).length > 0) {
                        this.dataFormSecond.includeCrowd = 2
                    } else {
                        this.dataFormSecond.dyUserTarget.include_crowd_pack_id_arr = []
                    }

                    if((_data.dyUserTarget.exclude_crowd_pack_id_arr || []).length > 0) {
                        this.dataFormSecond.excludeCrowd = 2
                    } else {
                        this.dataFormSecond.dyUserTarget.exclude_crowd_pack_id_arr = []
                    }
                    
                    this.districtReceive(_data.dyUserTarget || {})
                    this.audienceReceive(_data.dyUserTarget || {})
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        //取消
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/taskManage'
                    })
                }
            })
        },
        //提交
        handleSubmit() {
            this.$refs.dataFormSecond.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    let districtData = this.districtCurValue
                    let audienceData = this.audienceCurValue
                    let method = 'post'
                    if(this.pageType == 'copy') {
                        this.dataFormSecond.missionId = undefined
                    } else if(this.id != 0) {
                        method = 'put'
                    }

                    let body = {
                        method,
                        data: {
                            ...this.dataFormSecond,
                            ...this.dataFormFirst,
                            ...{
                                dyUserTarget: {
                                    ...audienceData,
                                    ...districtData,
                                    include_crowd_pack_id_arr: this.dataFormSecond.dyUserTarget.include_crowd_pack_id_arr,
                                    exclude_crowd_pack_id_arr: this.dataFormSecond.dyUserTarget.exclude_crowd_pack_id_arr
                                }
                            }
                        }
                    }

                    if(districtData.district == 'NONE') {
                        body.data.dyUserTarget.city = undefined
                    }

                    this.$api.core.deliverManage.submitTaskData( body ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success(`提交成功`)
                            this.$router.push({
                                path: '/taskManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        // 品牌车系 - 获取主体
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id, option) {
            this.dataFormSecond.principalName = option.data.attrs.name
            this.dataFormSecond.carSeriesId = undefined
            this.dataFormSecond.carSeriesName = ''
            this.getPrincipalSeriesList(id)
        },
        onChangeCarSeries(id, option) {
            this.dataFormSecond.carSeriesName = option.data.attrs.name
        },
        // 品牌车系 - 获取主体下车系
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        // 品牌车系 - 添加车系
        handleAddCar() {
            let carSeriesId = this.dataFormSecond.carSeriesId
            if(!_.find(this.dataFormSecond.carSeriesRelationList, _.matchesProperty('carSeriesId', carSeriesId))) {
                this.dataFormSecond.carSeriesRelationList.push({
                    principalId: this.dataFormSecond.principalId,
                    principalName: this.dataFormSecond.principalName,
                    carSeriesId: this.dataFormSecond.carSeriesId,
                    carSeriesName: this.dataFormSecond.carSeriesName,
                    detailKpi: '',
                    targetBid: '',
                    dailyBudget: '',
                })
            } else {
                this.$message.error('该车系已添加')
            }

            if(this.dataFormSecond.carSeriesRelationList.length == 1 && this.isEditMissionName) {
                this.dataFormSecond.missionName = this.formatMissionName()
            }

            this.$refs.carSeriesId.onFieldChange()
        },
        // 品牌车系 - 批量录入
        handleBatch(type, txt) {
            let thisVal = ''
            let totalVal = 0
            switch(type) {
                case 'detailKpi':
                    thisVal = this.batchData.detailKpi
                    break;
                case 'targetBid':
                    thisVal = this.batchData.targetBid
                    break;
                case 'dailyBudget':
                    thisVal = this.batchData.dailyBudget
                    break;
            }

            if(!thisVal) {
                this.$message.error(`请输入批量录入${txt}的数值`)
                return
            }

            this.dataFormSecond.carSeriesRelationList.map(item => {
                item[type] = thisVal
                totalVal += thisVal
            })
            if(type == 'detailKpi') {
                this.dataFormSecond.totalKpi = totalVal
            }
        },
        // 品牌车系 - 任务明细KPI计算任务总KPI
        onChangeInput() {
            let totalVal = 0
            this.dataFormSecond.carSeriesRelationList.map(item => {
                totalVal += item.detailKpi
            })
            this.dataFormSecond.totalKpi = totalVal || ''
        },
        // 品牌车系 - 删除
        handleDel(index) {
            this.dataFormSecond.carSeriesRelationList.splice(index, 1)
            this.onChangeInput()

            if(this.isEditMissionName) {
                this.dataFormSecond.missionName = this.formatMissionName()
            }
        },
        // 格式化任务名称
        formatMissionName() {
            let principalName = ''
            if(this.dataFormSecond.carSeriesRelationList.length > 0) {
                principalName = this.dataFormSecond.carSeriesRelationList[0].principalName
            }

            let placement = ''
            this.dataFormSecond.placementArr.map(m => this.placementOptions.map(n => {
                if(m == n.value) {
                    placement = `${placement}${placement ? '，' : ''}${n.label}`
                }
            }))

            let time = ''
            if(this.dataFormSecond.startTime) {
                time = `${moment(this.dataFormSecond.startTime).format('YYYYMMDD')}~${moment(this.dataFormSecond.endTime).format('YYYYMMDD')}`
            }

            return `${principalName}${placement}${time}`
        },
        //投放位置
        onChangePlacement() {
            if(this.isEditMissionName) {
                this.dataFormSecond.missionName = this.formatMissionName()
            }
        },
        // 编辑任务名称
        onEditMissionName(val) {
            this.isEditMissionName = false
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.steps-content {
    padding: 20px;
    margin: 25px 0 30px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    min-height: 200px;
}
.w-100 {
    width: 100%;
}
.w-70p {
    width: 70px;
}
.table {
    width: 100%;
    border: 1px solid #eee;
    .row {
        display: flex;
        justify-content: space-between;
        min-height: 40px;
        line-height: 1.5em;
        & +.row {
            border-top: 1px solid #eee;
        }
        &-1 .w-70p {
            margin-right: 31px;
        }
    }
    .col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 5px;
        text-align: center;
        & + .col {
            border-left: 1px solid #eee;
        }
        &-1 {
            width: 16.66%;
        }
        &-2 {
            width: 33.33%;
        }
    }
    .link {
        padding-left: 5px;
        color: #1890ff;
        cursor: pointer;
        text-decoration: underline;
    }
    /deep/ .ant-form-item {
        margin-bottom: 0;
        width: 100%;
    }
}

/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-collapse-arrow {
        position: static;
        transform: translateY(0);
    }
}
/deep/ .right-panel .header {
    display: block;
    padding: 0;
    line-height: unset;
}
/deep/ .right-panel-content .header {
    display: flex;
    align-items: center;
    padding: 2px 12px;
    background-color: transparent;
    line-height: unset;
}
</style>